import { Card } from 'reactstrap';
import React, { useState } from 'react';
import { isMobileOnly } from 'mobile-device-detect';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import Hashids from 'hashids/cjs';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ModalDialog from '../ModalDialog';
import { config } from '../../settings';
import { language, messages } from '../../utils/localeUtils';
import Icon from '../Icon';
import Placeholder from '../../../config/placeholder.config';
import { redirectAuth } from '../../utils/redirect';
const hashids = new Hashids('', 12);

function GroupListingCard({ group, auth, history, compact }) {
  const [joining, setJoining] = useState(false);
  const [requested, setRequested] = useState(false);

  const showGroupDetail = () => {
    history.push(`/groups/${hashids.encode(group.id)}`);
  };

  const joinGroup = (group) => {
    const { user } = auth;
    if (user) {
      if (!joining) {
        setJoining(true);
        axios.post(`${config.apiEndPoint}/groups/${group.id}/members`, { user }).then(() => {
          // TODO Update the list of requestedGroups so the button updates
          setJoining(false);
          setRequested(true);
        }).catch((err) => {
          console.log(err);
          Sentry.captureException(err);
          setJoining(false);
          setRequested(false);
          ModalDialog.show({
            title: 'Unable to join group',
            message: 'If you believe this was in error, please contact support through the main menu of this app.',
          });
        });
      } else {
        redirectAuth();
      }
    }
  };
  if (isMobileOnly || compact) {
    return (
      <ListItem alignItems="flex-start">
        <ListItemAvatar onClick={showGroupDetail}>
          <Avatar alt={group.name} src={group.image_url}>
            <img
              src={Placeholder.covers.venue}
              style={{
                objectFit: 'cover',
                height: 40,
                width: 40,
              }}
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          onClick={showGroupDetail}
          style={{ paddingRight: 36 }}
          primary={(
            <>
              <Typography
                component="div"
                variant="h6"
                color="textPrimary"
              >
                {group.name}
              </Typography>
            </>
          )}
          secondary={(
            <>
              {/* <Typography */}
              {/*  component="span" */}
              {/*  variant="body2" */}
              {/*  color="textPrimary" */}
              {/* > */}
              {/*  {'Last post 12/12/20'} */}
              {/* </Typography> */}
              <span style={{ flex: 1, lineHeight: '40px' }}>
                <Icon name={group.private ? 'lock' : 'globe'} />
                {/* TODO Include # of members - "Public - 22k members" */}
                <span style={{ marginLeft: 10 }}>{group.private ? 'Private' : 'Public'}</span>
              </span>
            </>
          )}
        />
        {/* TODO Consider adding this back - for now, keep it simple and handle directly in the group page */}
        {/*<ListItemSecondaryAction>*/}
        {/*  <button*/}
        {/*    className="btn btn-success"*/}
        {/*    style={{*/}
        {/*      minWidth: 72,*/}
        {/*      display: 'inline',*/}
        {/*    }}*/}
        {/*    onClick={() => joinGroup(group)}*/}
        {/*  >*/}
        {/*    {joining && !requested && (messages[language]?.requesting || 'Requesting')}*/}
        {/*    {!joining && !requested && (messages[language]?.join || 'Join')}*/}
        {/*    {!joining && requested && (messages[language]?.requested || 'Requested')}*/}
        {/*  </button>*/}
        {/*</ListItemSecondaryAction>*/}
      </ListItem>
    );
  }
  return (
    <Card style={{ marginBottom: 10, cursor: 'pointer' }}>
      <div
        onClick={showGroupDetail}
        style={{
          backgroundImage: `url(${group.image_url}), url(${Placeholder.covers.venue})`,
          width: '100%',
          height: 200,
          backgroundSize: 'cover',
        }}
      />
      <div style={{ textAlign: 'center' }}>
        <h3
          onClick={showGroupDetail}
          style={{
            alignSelf: 'flex-end',
            margin: 10,
          }}
        >
          {group.name}
        </h3>
        <div style={{ marginBottom: 30 }} onClick={showGroupDetail}>
          {/* TODO # of members etc */}
          <span style={{ flex: 1, lineHeight: '40px' }}>
            <Icon name={group.private ? 'lock' : 'globe'} />
            <span style={{ marginLeft: 10 }}>{group.private ? 'Private' : 'Public'}</span>
          </span>
        </div>
        {/* TODO Consider adding this back - for now, keep it simple and handle directly in the group page */}
        {/*<button*/}
        {/*  disabled={requested}*/}
        {/*  className="btn btn-secondary"*/}
        {/*  style={{ width: '90%', margin: 'auto', marginBottom: 20 }}*/}
        {/*  onClick={() => joinGroup(group)}*/}
        {/*>*/}
        {/*  {joining && !requested && (messages[language]?.requesting || 'Requesting')}*/}
        {/*  {!joining && !requested && (messages[language]?.join_group || 'Join Group')}*/}
        {/*  {!joining && requested && (messages[language]?.requested || 'Requested')}*/}
        {/*</button>*/}
      </div>
    </Card>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

export default connect(mapStateToProps)(withRouter(GroupListingCard));
