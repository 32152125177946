import React from 'react';
import Skeleton from '@material-ui/core/Skeleton';
import { isMobile } from 'mobile-device-detect';
import './style.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

class HumidorEntryCardSkeleton extends React.PureComponent {
  render() {
    if (isMobile) {
      return (
        <Card style={{ marginTop: 20 }}>
          <div style={{ padding: '8px 14px' }}>
            <Skeleton />
          </div>
          <div style={{ padding: 10 }}>
            <Row>
              <Col style={{ maxWidth: 95 }}>
                <Skeleton variant="circular" height={75} width={75} />
              </Col>
              <Col>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Col>
            </Row>
          </div>
        </Card>
      );
    }
    return (
      <div className="humidor-entry card-item" style={{ minHeight: 285, minWidth: isMobile ? 'auto' : 350, width: '100%' }}>
        <div style={{ marginTop: 30, marginBottom: 20 }}>
          <Skeleton width={200} />
        </div>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }
}

export default HumidorEntryCardSkeleton;
