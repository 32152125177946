import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'mobile-device-detect';
import Hashids from 'hashids/cjs';

import './style.scss';
import Avatar from '@material-ui/core/Avatar';
import Card from 'react-bootstrap/Card';
import { Badge, withStyles } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import Icon from '../Icon';
import HumidorEntryEditor from '../../pages/HumidorEntryEditor';
import SessionEditor from '../../pages/SessionEditor';
import Placeholder from '../../../config/placeholder.config';
import { config } from '../../settings';
import ModalDialog from '../ModalDialog';
import { selectAllHumidors } from '../../pages/Humidor/selectors';
import {
  addOrUpdateHumidorEntry as updateEntryAction, mergeHumidorEntry,
  removeHumidorEntry as removeHumidorEntryAction,
} from '../../pages/Humidor/actions';
import BottomSheet from '../bottom-sheet';
import TouchSpin from '../touch-spin';
import { Button, Col, Row } from 'reactstrap';
import Cigar from '../../models/Cigar';
import { addToast as actionAddToast } from '../../actions';
import { AppStore } from '../../stores';

const hashids = new Hashids('', 12);

const StyledBadge = withStyles((theme) => ({
  badge: {
    marginLeft: -14,
    marginTop: 14,
    color: 'white',
  },
}))(Badge);

let moveToHumidorId = null;

export function HumidorEntryCard(props) {
  // FIXME Put in useEffect
  const { width } = window.screen;

  const [entry, setEntry] = useState(props.item);
  const [scan, setScan] = useState(null);
  const [cigar, setCigar] = useState({ full_name: 'Pending...' });
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showHumidorEntryEditor, setShowHumidorEntryEditor] = useState(false);
  const [duplicateEntry, setDuplicateEntry] = useState(false);
  const [imageCropType, setImageCropType] = useState('cover');
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    setEntry({ ...props.item });
  }, [props.item]);

  useEffect(() => {
    entryImage();
    setScan(entry.scan);
    if (entry.scan.cigar) {
      setCigar(entry.scan.cigar);
    }
  }, [entry]);

  useEffect(() => {
    const { auth } = props;
    if (auth && auth.user && scan && scan.user) {
      setIsCurrentUser(auth.user && scan.user.id === auth.user.id);
    }
  }, [props.auth && props.auth.user && scan]);

  const moreEntryOptions = [{
    content: isMobile ? 'Move to another humidor' : 'Move',
    value: 'move',
    onClick: () => {
      moveToHumidorId = props.humidors && props.humidors.length > 0 && props.humidors[0].id;
      ModalDialog.show({
        title: 'Move to another humidor',
        message: (
          <select
            className="form-control"
            // value={moveToHumidorId}
            onChange={(e) => {
              moveToHumidorId = e.target.value;
              console.log(`Selected humidor: ${moveToHumidorId}`);
            }}
          >
            {props.humidors.map((humidor) => (
              <option value={humidor.id}>{humidor.name}</option>
            ))}
          </select>
        ),
        buttons: [{
          label: 'Cancel',
          onClick: () => {
            ModalDialog.close();
          },
        }, {
          label: 'Move',
          onClick: () => {
            console.log(JSON.stringify(entry));
            const newEntry = { ...entry };
            newEntry.humidor_id = moveToHumidorId || props.humidors[0].id;
            entry.qty = 0;
            mergeHumidorEntry(entry);
            props.updateEntry(newEntry);
            setTimeout(() => {
              ModalDialog.close();
            }, 200);
          },
        }],
      });
    },
  }, {
    content: isMobile ? 'Edit entry details' : 'Edit',
    value: 'edit',
    onClick: () => toggleHumidorEntryEditor(),
  }, {
    content: isMobile ? 'Duplicate entry' : 'Duplicate',
    value: 'duplicate',
    onClick: () => toggleHumidorEntryEditor(true),
  }, {
    content: isMobile ? 'Remove from humidor' : 'Remove',
    value: 'remove',
    onClick: () => {
      console.log(entry);
      ModalDialog.show({
        title: 'Remove from humidor',
        message: 'Are you sure you want to remove this from your humidor?',
        buttons: [{
          label: 'No, cancel',
          onClick: () => {
            ModalDialog.close();
          },
        }, {
          label: 'Yes, remove',
          style: { color: 'rgb(239, 81, 100)' },
          onClick: () => {
            axios.delete(`${config.apiEndPoint}/humidors/${entry.humidor_id}/entries?entry_ids=${entry.id}`)
              .then(() => {
                console.log('Deleted successfully');
                props.removeHumidorEntry(entry); // FIXME Whole axios call should be part of this action
                ModalDialog.close();
              });
          },
        }],
      });
    },
  }];

  const smokeNow = () => {
    console.log('Smoking entry:');
    console.log(entry);
    const currentCount = entry.qty;
    const remainingCount = currentCount - 1;

    let title;
    const cigarName = entry.scan.cigar ? entry.scan.cigar.full_name : 'one';
    const pluralName = entry.scan.cigar ? `${cigarName}s` : 'of these';
    const humidorName = entry.humidor ? entry.humidor.name : 'this humidor';
    if (remainingCount === 0) {
      console.log(`This is your last ${cigarName} left in ${humidorName}`);
      title = `This is your last ${cigarName} left in ${humidorName}`;
    } else {
      console.log(`You have ${remainingCount} ${pluralName} left in ${humidorName}`);
      title = `You have ${remainingCount} ${pluralName} left in ${humidorName}`;
    }
    AppStore.update((s) => {
      s.sessionStarted = true;
    });
    toggleSessionModal();
    setTimeout(() => {
      props.addToast({
        content: (<>{title}</>),
        duration: 6000,
      });
    }, 250);
  };

  const showCigarDetail = () => {
    let user;
    if (props.auth && props.auth.user) {
      user = props.auth.user;
    }
    if (cigar && cigar.id) {
      const humidorName = entry.humidor && entry.humidor.name;
      const humidorId = entry.humidor_id || (entry.humidor && entry.humidor.id);
      const linkUrl = user && parseInt(user.id) === parseInt(scan.user.id) ? `/cigars/${hashids.encode(cigar.id)}?entryId=${entry.id}&entryQty=${entry.qty}${humidorId ? `&humidorId=${humidorId}` : ''}${humidorName ? `&humidorName=${humidorName}` : ''}` : `/cigars/${hashids.encode(cigar.id)}`;
      props.history.push({
        pathname: linkUrl,
        state: { cigar, entry: user && parseInt(user.id) === parseInt(scan.user.id) ? entry : undefined },
      });
    } else {
      props.history.push(`/scans/pending/${hashids.encode(scan.id)}`);
    }
  };

  const entryImage = () => {
    console.log('Entry details:');
    console.log(entry);
    // FIXME Should this flow be handled server-side?
    if (entry.image_url) {
      setImageUrl(`https://aouikjkrpo.cloudimg.io/crop/200x200/webp/${entry.image_url}`);
      return;
    }
    if (scan) {
      // if (scan.sessions && scan.sessions.length > 0) {
      //   for (let i = 0; i < scan.sessions.length; i++) {
      //     const session = scan.sessions[i];
      //     if (session.image_url) {
      //       setImageUrl(`https://aouikjkrpo.cloudimg.io/crop/200x200/webp/${session.image_url}`);
      //       return;
      //     }
      //   }
      // }
      // if (scan.image_url) {
      //   // return `https://aouikjkrpo.cloudimg.io/v7/${bandImage}?w=200&h=200&func=crop&force_format=webp`;
      //   setImageUrl(`https://aouikjkrpo.cloudimg.io/crop/200x200/webp/${scan.image_url}`);
      //   return;
      // }
      if (scan.cigar) {
        const bandImage = Cigar.getBandImage(scan.cigar);
        if (bandImage !== Placeholder.cigar) {
          setImageCropType('contain');
          setImageUrl(`https://aouikjkrpo.cloudimg.io/v7/${bandImage}?w=200&h=200&func=bound&force_format=webp`);
          return;
        }
      }
    }
    setImageUrl(Placeholder.cigar);
  };

  const entryTitle = () => {
    let title = cigar.full_name;
    if (entry.vitola) {
      title += ` ${entry.vitola.formatted_name}`;
    }
    return title;
  };

  // FIXME Move to utils?
  const formatDate = (dateStr) => {
    // TODO Include user's locale?
    try {
      console.log(dateStr);
      return formatDistance(new Date(dateStr.replace(' ', 'T')), new Date(), { addSuffix: true });
    } catch (e) {
      return 'N/A';
    }
  };

  const toggleSessionModal = () => {
    console.log('Showing session modal...');
    setShowSessionModal(!showSessionModal);
  };

  const closeSessionModal = () => {
    setShowSessionModal(false);
  };

  const toggleHumidorEntryEditor = (duplicate) => {
    setDuplicateEntry(duplicate || false);
    setShowHumidorEntryEditor(!showHumidorEntryEditor);
  };

  const closeHumidorEntryEditor = () => {
    setShowHumidorEntryEditor(false);
  };

  const renderPurchaseDetails = () => {
    if (!entry.bought_from && !entry.price) {
      return 'Purchase location unavailable';
    }
    if (entry.bought_from) {
      let text = `Purchased at ${entry.bought_from.name}`;
      if (entry.price) {
        const packageType = entry.price_type === 'Single' ? 'Single' : `${entry.price_type} of ${entry.price_qty}`;
        const pricePerCigar = parseFloat(entry.price) / entry.price_qty;
        text += ` for $${entry.price.toFixed(2)} / ${packageType} ${entry.price_type !== 'Single' ? `($${pricePerCigar.toFixed(2)} / cigar)` : ''}`;
      }
      return text;
    }
    const packageType = entry.price_type === 'Single' ? 'Single' : `${entry.price_type} of ${entry.price_qty}`;
    const pricePerCigar = parseFloat(entry.price) / entry.price_qty;
    return `$${entry.price.toFixed(2)} / ${packageType} ${entry.price_type !== 'Single' ? `($${pricePerCigar.toFixed(2)} / cigar)` : ''}`;
  };

  const renderGiftedFromDetails = () => {
    if (!entry.gifted_from) {
      return null;
    }
    // FIXME Make this a link to the user's profile?
    return (
      <div
        style={{
          display: 'flex',
          marginTop: 5,
        }}
      >
        <Avatar
          src={entry.gifted_from.image_url}
          style={{
            height: 20,
            width: 20,
            marginRight: 10,
          }}
        >
          {entry.gifted_from.full_name.charAt(0)}
        </Avatar>
        <div style={{ flex: 'auto' }}>
          {`Gift from ${entry.gifted_from.full_name}`}
        </div>
      </div>
    );
  };

  const updateEntry = () => {
    console.log('Updating entry in onSave()...');
    props.updateEntry({
      id: entry.id,
      qty: entry.qty - 1,
      scan: {
        user_id: entry.scan.user_id,
      },
      humidor_id: entry.humidor_id,
    }, () => {
      setTimeout(() => {
        // TODO Implement this
        // setLoading(false);
      }, 100);
    });
  };

  const renderSessionModal = () => {
    console.log('renderSessionModal');
    return (
      <SessionEditor
        open={showSessionModal}
        // TODO Pass in the scan image or let them upload a new one? I'm thinking force a new one... the scan image will be used by default either way
        scan={entry.scan}
        cigar={entry.scan.cigar}
        vitola={entry.vitola}
        purchaseLocation={entry.bought_from}
        purchasePrice={entry.price}
        purchasePriceType={entry.price_type}
        purchasePriceQty={entry.price_qty}
        giftedFrom={entry.gifted_from}
        toggle={toggleSessionModal}
        onClose={closeSessionModal}
        onSave={updateEntry}
      />
    );
  };

  const renderHumidorEntryEditor = () => {
    console.log('renderHumidorEntryEditor');
    return (
      <HumidorEntryEditor
        entry={duplicateEntry ? { ...props.item, id: null } : props.item}
        open={showHumidorEntryEditor}
        toggle={toggleHumidorEntryEditor}
        onClose={closeHumidorEntryEditor}
      />
    );
  };

  // console.log('entry');
  // console.log(entry);
  if (isMobile) {
    return (
      <Card style={{ marginTop: 20 }}>
        <div style={{ padding: '8px 14px' }}>
          {/* FIXME I think we can route to a separate page where they can search for the cigar if they know it */}
          <span onClick={showCigarDetail} style={{ cursor: 'pointer' }}>{entryTitle()}</span>
          {isCurrentUser && (
            <Icon
              name="more-horizontal"
              style={{ float: 'right' }}
              onClick={() => {
                BottomSheet.show({
                  items: moreEntryOptions,
                });
              }}
            />
          )}
        </div>
        <div style={{ padding: 10 }}>
          <Row>
            <span onClick={showCigarDetail}>
              <Col style={{ maxWidth: 105 }}>
                <StyledBadge color="primary" badgeContent={entry.qty && entry.qty > 0 ? entry.qty : null}>
                  <Avatar
                    src={imageUrl}
                    className={imageCropType === 'contain' ? 'avatar-contained' : ''}
                    style={{
                      height: 75,
                      width: 75,
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 5px, rgba(255, 255, 255, 0.2) 0px 0px 10px',
                      cursor: 'pointer',
                    }}
                  >
                    <img src={Placeholder.cigar} style={{ height: 75, width: 75 }} />
                  </Avatar>
                </StyledBadge>
              </Col>
            </span>
            <Col>
              <span onClick={showCigarDetail} style={{ cursor: 'pointer' }}>
                <div>
                  <span>{`Added ${formatDate(entry.timestamp)} on ${new Date(entry.timestamp).toLocaleDateString()}`}</span>
                </div>
                <div>
                  <span>{renderPurchaseDetails()}</span>
                  <span>{renderGiftedFromDetails()}</span>
                </div>
              </span>
              {isCurrentUser && entry.order && (
                <div><Link to={`/orders/${hashids.encode(entry.order.id)}`}>{`View Order #${entry.order.order_number}`}</Link></div>
              )}
              { entry.notes && (
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <Icon name={['fas', 'quote-left']} vendor="fa" style={{ color: '#d9d9d9', width: 24 }} />
                  <div style={{ flex: 'auto', marginLeft: 6, marginBottom: 10 }}>
                    {entry.notes}
                  </div>
                </div>
              )}
            </Col>
          </Row>
          {isCurrentUser && (
            <>
              <hr />
              <div className="row">
                <div className="col-3" style={{ textAlign: 'right', lineHeight: '34px' }}>
                  <span>{width > 320 ? 'Quantity' : 'Qty'}</span>
                </div>
                <div className="col-9">
                  <TouchSpin
                    max={100}
                    min={1}
                    step={1}
                    value={entry.qty}
                    onChange={(value) => {
                      entry.qty = value;
                      if (value && value > 0) {
                        props.updateEntry(entry);
                      }
                    }}
                  />
                </div>
              </div>
              <hr />
              <div style={{ textAlign: 'center' }}>
                <Button onClick={smokeNow} outline color="success" style={{ maxWidth: 280, width: 'calc(100% - 20px)' }}>Smoke Now</Button>
              </div>
            </>
          )}
        </div>

        {/* FIXME Look into better handling for these - I think this helps performance, but there are no animations this way */}
        {/* FIXME One alternative is a callback to the main view, but I don't like rendering a bunch all in one file like that */}
        { showHumidorEntryEditor && renderHumidorEntryEditor() }
        { showSessionModal && renderSessionModal() }
      </Card>
    );
  }
  return (
    <div className="humidor-entry card-item">
      <div className="humidor-card-item" style={{ borderRadius: 3, padding: 20 }}>
        <div
          onClick={showCigarDetail}
          className="img"
          style={{
            cursor: 'pointer',
            backgroundImage: `url(${imageUrl})`,
            height: 100,
            width: 100,
            borderRadius: '50%',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            marginBottom: 20,
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 5px, rgba(255, 255, 255, 0.2) 0px 0px 10px',
          }}
        />
        {isCurrentUser && (
          <Icon
            name="more-horizontal"
            style={{ position: 'absolute', top: 20, right: 20 }}
            onClick={() => {
              BottomSheet.show({
                items: moreEntryOptions,
              });
            }}
          />
        )}
        <div className="author" onClick={showCigarDetail}>
          {`Added ${formatDate(entry.timestamp)}`}
        </div>
        <div style={{ position: 'absolute', top: 30, left: 160 }}>
          <h3 onClick={showCigarDetail} style={{ cursor: 'pointer' }}>{entryTitle()}</h3>
          <div>
            <span>{renderPurchaseDetails()}</span>
            <span>{renderGiftedFromDetails()}</span>
          </div>
          {entry.notes && (
            <div style={{ display: 'flex', marginTop: 10 }}>
              <Icon name={['fas', 'quote-left']} vendor="fa" style={{ color: '#d9d9d9', width: 24 }} />
              <div style={{ flex: 'auto', marginLeft: 6, marginBottom: 10 }}>
                {entry.notes}
              </div>
            </div>
          )}
        </div>
        {isCurrentUser && entry.order && (
          <div><Link to={`/orders/${hashids.encode(entry.order.id)}`}>{`View Order #${entry.order.order_number}`}</Link></div>
        )}
        {isCurrentUser && (
          <div style={{ maxWidth: 200, position: 'absolute', right: 30, bottom: 16, textAlign: 'center' }}>
            <span>Quantity Available</span>
            <TouchSpin
              max={100}
              min={1}
              step={1}
              value={entry.qty}
              onChange={(value) => {
                entry.qty = value;
                props.updateEntry(entry);
              }}
            />
            <div style={{ textAlign: 'center', marginTop: 10 }}>
              <Button onClick={smokeNow} outline color="success" style={{ maxWidth: 280, width: 'calc(100% - 20px)' }}>Smoke Now</Button>
            </div>
          </div>
        )}
      </div>

      { showSessionModal && renderSessionModal() }
      { showHumidorEntryEditor && renderHumidorEntryEditor() }
    </div>
  );
}

HumidorEntryCard.propTypes = {
  item: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const auth = state.get('auth').toJS();
  return {
    auth,
    humidors: auth.user ? selectAllHumidors(auth.user.id)(state) : [],
  };
}

const mapDispatchToProps = (dispatch) => ({
  removeHumidorEntry: (entry) => dispatch(removeHumidorEntryAction(entry)),
  updateEntry: (entry, callback) => dispatch(updateEntryAction(entry, callback)),
  addToast: (data) => dispatch(actionAddToast(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HumidorEntryCard));
