import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'mobile-device-detect';
import Hashids from 'hashids/cjs';
import './style.scss';
import Avatar from '@material-ui/core/Avatar';
import Card from 'react-bootstrap/Card';
import { Badge, withStyles } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import Icon from '../Icon';
import HumidorEntryEditor from '../../pages/HumidorEntryEditor';
import SessionEditor from '../../pages/SessionEditor';
import Placeholder from '../../../config/placeholder.config';
import { config } from '../../settings';
import ModalDialog from '../ModalDialog';
import { selectAllHumidors } from '../../pages/Humidor/selectors';
import {
  addOrUpdateHumidorEntry as updateEntryAction, mergeHumidorEntry,
  removeHumidorEntry as removeHumidorEntryAction,
} from '../../pages/Humidor/actions';
import BottomSheet from '../bottom-sheet';
import TouchSpin from '../touch-spin';
import Cigar from '../../models/Cigar';
import { addToast as actionAddToast } from '../../actions';
import { AppStore } from '../../stores';

const hashids = new Hashids('', 12);

const StyledBadge = withStyles((theme) => ({
  badge: {
    marginLeft: -14,
    marginTop: 14,
    color: 'white',
  },
}))(Badge);

let moveToHumidorId = null;

class HumidorEntryCardGrid extends PureComponent {
  moreEntryOptions = [{
    content: 'Smoke Now',
    value: 'smoke',
    onClick: () => {
      const entry = this.props.item;
      console.log(entry);
      const currentCount = entry.qty;
      const remainingCount = currentCount - 1;

      let title;
      if (remainingCount === 0) {
        if (entry.humidor) {
          title = `This is your last ${entry.scan.cigar.full_name} left in ${entry.humidor.name}`;
        } else {
          title = `This is your last ${entry.scan.cigar.full_name} left in this humidor`;
        }
      } else if (entry.humidor) {
        title = `You have ${remainingCount} ${entry.scan.cigar.full_name}'s left in ${entry.humidor.name}`;
      } else {
        title = `You have ${remainingCount} ${entry.scan.cigar.full_name}'s left in this humidor`;
      }
      AppStore.update((s) => {
        s.sessionStarted = true;
      });
      this.toggleSessionModal();
      this.props.addToast({
        content: (<>{title}</>),
        duration: 6000,
      });
    },
  }, {
    content: isMobile ? 'Move to another humidor' : 'Move',
    value: 'move',
    onClick: () => {
      moveToHumidorId = this.props.humidors && this.props.humidors.length > 0 && this.props.humidors[0].id;
      ModalDialog.show({
        title: 'Move to another humidor',
        message: (
          <select
            className="form-control"
            onChange={(e) => {
              moveToHumidorId = e.target.value;
            }}
          >
            {this.props.humidors.map((humidor) => (
              <option value={humidor.id}>{humidor.name}</option>
            ))}
          </select>
        ),
        buttons: [{
          label: 'Cancel',
          onClick: () => {
            ModalDialog.close();
          },
        }, {
          label: 'Move',
          onClick: () => {
            const entry = { ...this.props.item };
            console.log(JSON.stringify(entry));
            const newEntry = { ...entry };
            newEntry.humidor_id = moveToHumidorId || this.props.humidors[0].id;
            entry.qty = 0;
            mergeHumidorEntry(entry);
            this.props.updateEntry(newEntry);
            setTimeout(() => {
              ModalDialog.close();
            }, 200);
          },
        }],
      });
    },
  }, {
    content: isMobile ? 'Edit entry details' : 'Edit',
    value: 'edit',
    onClick: () => this.toggleHumidorEntryEditor(),
  }, {
    content: isMobile ? 'Remove from humidor' : 'Remove',
    value: 'remove',
    onClick: () => {
      console.log(this.props.item);
      ModalDialog.show({
        title: 'Remove from humidor',
        message: 'Are you sure you want to remove this from your humidor?',
        buttons: [{
          label: 'No, cancel',
          onClick: () => {
            ModalDialog.close();
          },
        }, {
          label: 'Yes, remove',
          style: { color: 'rgb(239, 81, 100)' },
          onClick: () => {
            axios.delete(`${config.apiEndPoint}/humidors/${this.props.item.humidor_id}/entries?entry_ids=${this.props.item.id}`)
              .then(() => {
                console.log('Deleted successfully');
                this.props.removeHumidorEntry(this.props.item); // FIXME Whole axios call should be part of this action
                ModalDialog.close();
                // TODO Update contents
              });
          },
        }],
      });
    },
  }];

  constructor(props) {
    super(props);
    this.state = {
      showSessionModal: false,
      showHumidorEntryEditor: false,
    };
  }

  showCigarDetail = (cigar) => {
    const entry = this.props.item;
    const { scan } = entry;
    const humidorName = entry.humidor && entry.humidor.name;
    const humidorId = entry.humidor_id || (entry.humidor && entry.humidor.id);
    if (cigar && cigar.id) {
      const linkUrl = cigar.id ? `/cigars/${hashids.encode(cigar.id)}?entryId=${entry.id}&entryQty=${entry.qty}${humidorId ? `&humidorId=${humidorId}` : ''}${humidorName ? `&humidorName=${humidorName}` : ''}` : `/scans/pending/${hashids.encode(scan.id)}`;
      this.props.history.push({ pathname: linkUrl, state: { cigar } });
    }
  };

  entryImage = () => {
    const entry = this.props.item;
    const { scan } = entry;

    // FIXME Should this flow be handled server-side?
    if (entry.image_url) {
      return `https://aouikjkrpo.cloudimg.io/crop/200x200/webp/${entry.image_url}`;
    }
    if (scan) {
      // if (typeof scan.sessions !== 'undefined' && scan.sessions.length > 0) {
      //   for (let i = 0; i < scan.sessions.length; i++) {
      //     const session = scan.sessions[i];
      //     if (session.image_url) {
      //       return `https://aouikjkrpo.cloudimg.io/crop/200x200/webp/${session.image_url}`;
      //     }
      //   }
      // }
      // if (scan.image_url) {
      //   // return `https://aouikjkrpo.cloudimg.io/v7/${bandImage}?w=200&h=200&func=crop&force_format=webp`;
      //   return `https://aouikjkrpo.cloudimg.io/crop/200x200/webp/${scan.image_url}`;
      // }
      if (scan.cigar) {
        const bandImage = Cigar.getBandImage(scan.cigar);
        if (bandImage !== Placeholder.cigar) {
          this.setState({ imageCropType: 'contain' });
          return `https://aouikjkrpo.cloudimg.io/v7/${bandImage}?w=200&h=200&func=bound&force_format=webp`;
        }
      }
    }
    return Placeholder.cigar;
  };

  entryTitle = () => {
    const entry = this.props.item;
    const { scan } = entry;
    const cigar = scan.cigar || { full_name: 'Pending...' };
    let title = cigar.full_name;
    if (entry.vitola) {
      title += ` ${entry.vitola.formatted_name}`;
    }
    return title;
  };

  // FIXME Move to utils?
  formatDate = (dateStr) => {
    // TODO Include user's locale?
    try {
      console.log(dateStr);
      return formatDistance(new Date(dateStr.replace(' ', 'T')), new Date(), { addSuffix: true });
    } catch (e) {
      return 'N/A';
    }
  };

  toggleSessionModal = () => {
    console.log('Showing session modal...');
    this.setState({
      showSessionModal: !this.showSessionModal,
    });
  };

  closeSessionModal = () => {
    this.setState({
      showSessionModal: false,
    });
  };

  toggleHumidorEntryEditor = () => {
    this.setState({
      showHumidorEntryEditor: !this.showHumidorEntryEditor,
    });
  };

  closeHumidorEntryEditor = () => {
    this.setState({
      showHumidorEntryEditor: false,
    });
  };

  renderPurchaseDetails = () => {
    const entry = this.props.item;
    if (!entry.bought_from) {
      return 'Purchase location unavailable';
    }
    let text = `Purchased at ${entry.bought_from.name}`;
    if (entry.price) {
      const packageType = entry.price_type === 'Single' ? 'Single' : `${entry.price_type} of ${entry.price_qty}`;
      text += ` for $${entry.price} / ${packageType}`;
    }
    return text;
  };

  renderSessionModal = () => (
    <SessionEditor
      open={this.state.showSessionModal}
      cigar={this.props.item.scan.cigar}
      toggle={this.toggleSessionModal}
      onClose={this.closeSessionModal}
      onSave={() => {
        const entry = this.props.item;
        this.props.updateEntry({
          id: entry.id,
          qty: entry.qty - 1,
          scan: {
            user_id: entry.scan.user_id,
          },
          humidor_id: entry.humidor_id,
        }, () => {
          this.setState({
            loading: false,
          });
        });
      }}
    />
  );

  renderHumidorEntryEditor = () => (
    <HumidorEntryEditor
      entry={this.props.item}
      open={this.state.showHumidorEntryEditor}
      toggle={this.toggleHumidorEntryEditor}
      onClose={this.closeHumidorEntryEditor}
    />
  );

  render() {
    const entry = this.props.item;
    const { auth } = this.props;
    const { scan } = entry;
    const cigar = scan.cigar || { full_name: 'Pending...' };

    const isCurrentUser = auth.user && scan.user.id === auth.user.id;

    // FIXME Ensure this UI has a circle-cropped image, the 3 dots for actions, and
    if (isMobile) {
      return (
        <Card style={{ marginTop: 20 }}>
          <div style={{ padding: '8px 14px' }}>
            {/* FIXME I think we can route to a separate page where they can search for the cigar if they know it */}
            {isCurrentUser && (
              <Icon
                name="more-horizontal"
                style={{ float: 'right' }}
                onClick={() => {
                  BottomSheet.show({
                    items: this.moreEntryOptions,
                  });
                }}
              />
            )}
          </div>
          <div style={{ padding: 10, textAlign: 'center' }}>
            <span onClick={() => this.showCigarDetail(cigar)}>
              <StyledBadge color="primary" badgeContent={entry.qty}>
                <Avatar
                  src={this.entryImage()}
                  className={this.state.imageCropType === 'contain' ? 'avatar-contained' : ''}
                  style={{ height: 75, width: 75, cursor: 'pointer' }}
                >
                  <img src={Placeholder.cigar} style={{ height: 75, width: 75 }} />
                </Avatar>
              </StyledBadge>
            </span>
          </div>
          <div style={{ textAlign: 'center', margin: 10, cursor: 'pointer' }} onClick={() => this.showCigarDetail(cigar)}>{this.entryTitle()}</div>

          {/* FIXME Look into better handling for these - I think this helps performance, but there are no animations this way */}
          {/* FIXME One alternative is a callback to the main view, but I don't like rendering a bunch all in one file like that */}
          {/* TODO Look into how toasts are rendered - they use Redux from the looks of it, which would definitely be a good way to handle all of these */}
          {this.state.showHumidorEntryEditor && this.renderHumidorEntryEditor()}
          {this.state.showSessionModal && this.renderSessionModal()}
        </Card>
      );
    }
    return (
      <div style={{ padding: 10 }}>
        <div className="top-rated-item">
          <div style={{ textAlign: 'center', paddingTop: 30 }}>
            <span onClick={() => this.showCigarDetail(cigar)} style={{ cursor: 'pointer' }}>
              <img
                src={this.entryImage()}
                title={cigar.full_name}
                alt={cigar.full_name}
                style={{
                  boxShadow: '0 0 0 5px rgba(0,0,0,.1),0 0 10px hsla(0,0%,100%,.2)',
                  borderRadius: '50%',
                  height: 80,
                  width: 80,
                }}
              />
            </span>
            {isCurrentUser && (
              <Icon
                name="more-horizontal"
                style={{ position: 'absolute', top: 20, right: 20 }}
                onClick={() => {
                  BottomSheet.show({
                    items: this.moreEntryOptions,
                  });
                }}
              />
            )}
          </div>

          <span onClick={() => this.showCigarDetail(cigar)}>
            <div className="name" style={{ textAlign: 'center', cursor: 'pointer' }}>
              <div>
                <h4 style={{ fontSize: '12px' }}>
                  {cigar.brand}
                </h4>
                <h5 style={{ marginBottom: 0 }}>{`${cigar.name} ${entry.vitola ? entry.vitola.formatted_name : ''}`.trim()}</h5>
              </div>
            </div>
          </span>

          <span onClick={() => this.showCigarDetail(cigar)}>
            <div style={{ padding: 20 }}>
              <div style={{ padding: 12 }}>
                <div onClick={() => this.showCigarDetail(cigar)}>
                  <span>{this.renderPurchaseDetails()}</span>
                </div>
                { entry.notes && (
                  <div style={{ display: 'flex', marginTop: 10 }}>
                    <Icon name={['fas', 'quote-left']} vendor="fa" style={{ color: '#d9d9d9', width: 24 }} />
                    <div style={{ flex: 'auto', marginLeft: 6, marginBottom: 10 }}>
                      {entry.notes}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </span>

          <div style={{ padding: 12 }}>
            <div onClick={() => this.showCigarDetail(cigar)}>
              <span>{`Added ${this.formatDate(entry.timestamp)}`}</span>
            </div>
          </div>

          {isCurrentUser && entry.order && (
            <div><Link to={`/orders/${hashids.encode(entry.order.id)}`}>{`Order #${entry.order.order_number}`}</Link></div>
          )}

          {isCurrentUser && (
            <>
              <hr />
              <div style={{ padding: 10 }}>
                <TouchSpin
                  max={100}
                  min={1}
                  step={1}
                  value={entry.qty}
                  onChange={(value) => {
                    // INFO `TypeError: Attempted to assign to readonly property` when using `entry.qty = value;` directly
                    const newEntry = { ...entry };
                    newEntry.qty = value;
                    this.props.updateEntry(newEntry);
                    this.forceUpdate();
                  }}
                />
              </div>
            </>
          )}
        </div>
        {this.state.showSessionModal && this.renderSessionModal()}
        {this.state.showHumidorEntryEditor && this.renderHumidorEntryEditor()}
      </div>
    );
  }
}

HumidorEntryCardGrid.propTypes = {
  item: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const auth = state.get('auth').toJS();
  return {
    auth,
    humidors: auth.user ? selectAllHumidors(auth.user.id)(state) : [],
  };
}

const mapDispatchToProps = (dispatch) => ({
  removeHumidorEntry: (entry) => dispatch(removeHumidorEntryAction(entry)),
  updateEntry: (entry, callback) => dispatch(updateEntryAction(entry, callback)),
  addToast: (data) => dispatch(actionAddToast(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HumidorEntryCardGrid));
